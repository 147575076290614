<script>
import { Bar } from "vue-chartjs";

export default {
  name: "FrekuensiPendaftar",
  extends: Bar,
  props: {
    data: {
      type: Array,
    },
  },
  mounted() {
    this.renderChart(
      {
        labels: [
          "Januari",
          "Februari",
          "Maret",
          "April",
          "Mei",
          "Juni",
          "Juli",
          "Agustus",
          "September",
          "Oktober",
          "Nopember",
          "Desember",
        ],
        datasets: [
          {
            label: "Total Anggota",
            backgroundColor: "#3498db",
            data: this.data,
          },
        ],
      },
      { responsive: true, maintainAspectRatio: false }
    );
  },
};
</script>
