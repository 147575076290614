<script>
import { Pie } from "vue-chartjs";

export default {
  extends: Pie,
  props: {
    data: {
      type: Array,
    },
  },
  mounted() {
    this.gradient2 = this.$refs.canvas
      .getContext("2d")
      .createLinearGradient(0, 0, 0, 450);

    this.renderChart(
      {
        labels: [
          "Disetujui",
          "Ditolak",
          "Menunggu Persetujuan",
          "Menunggu Konfirmasi",
          "Menunggu Pembayaran",
          "Menunggu Pencetakan",
        ],
        datasets: [
          {
            backgroundColor: [
              "#2ecc71",
              "#e74c3c",
              "#34495e",
              "#f1c40f",
              "#e67e22",
              "#3498db",
            ],
            data: this.data,
          },
        ],
      },
      { responsive: true, maintainAspectRatio: false }
    );
  },
};
</script>
