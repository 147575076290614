<template>
  <div class="card">
    <h5 class="card-header">{{ title }}</h5>
    <div class="card-body">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Propinsi</th>
            <th scope="col">Total Anggota</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(wilayah, idx) in data" :key="idx">
            <th scope="row">{{ idx + 1 }}</th>
            <td>{{ wilayah.Propinsi.nama }}</td>
            <td>{{ wilayah.total_anggota }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: Array,
    title: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped></style>
