<template>
  <main>
    <div class="container-fluid px-4">
      <h1 class="mt-4">Dashboard</h1>
      <div class="row mb-3">
        <div
          class="col-md-12"
          v-if="
            currentUser.role === 'Super User' || currentUser.role === 'Admin'
          "
        >
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Filter Wilayah</h5>
              <div class="row">
                <div class="col-md-2" v-if="currentUser.role === 'Super User'">
                  <v-select
                    v-model="propinsi_id"
                    :options="provinsi"
                    :reduce="(provinsi) => provinsi.id"
                    label="nama"
                    @input="onProvinceChange"
                    aria-placeholder="Provinsi"
                  >
                    <template #search="{ attributes, events }">
                      <input
                        class="vs__search"
                        v-bind="attributes"
                        v-on="events"
                      />
                    </template>
                  </v-select>
                </div>
                <div class="col-md-2">
                  <v-select
                    v-model="kota_id"
                    :options="kota"
                    :reduce="(kota) => kota.id"
                    label="nama"
                    @input="onKotaChange"
                  >
                    <template #search="{ attributes, events }">
                      <input
                        class="vs__search"
                        v-bind="attributes"
                        v-on="events"
                      />
                    </template>
                  </v-select>
                </div>
                <div class="col-md-2">
                  <v-select
                    v-model="kecamatan_id"
                    :options="kecamatan"
                    :reduce="(kecamatan) => kecamatan.id"
                    label="nama"
                    aria-placeholder="Kecamatan"
                  >
                    <template #search="{ attributes, events }">
                      <input
                        class="vs__search"
                        v-bind="attributes"
                        v-on="events"
                      />
                    </template>
                  </v-select>
                </div>
                <div class="col-md-2">
                  <div
                    class="btn-group"
                    role="group"
                    aria-label="Basic example"
                  >
                    <button
                      type="button"
                      class="btn btn-primary"
                      @click="initialize"
                    >
                      <i class="bi bi-filter"></i> Filter
                    </button>
                    <button
                      type="button"
                      class="btn btn-secondary"
                      @click="resetFilter"
                    >
                      <i class="bi bi-x"></i>
                      Reset
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-4 col-md-6">
          <div class="card bg-primary text-white mb-4">
            <div class="card-body">Total Anggota</div>
            <div
              class="
                card-footer
                d-flex
                align-items-center
                justify-content-between
              "
            >
              <h2>{{ total_anggota }}</h2>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-md-6">
          <div class="card bg-warning text-white mb-4">
            <div class="card-body">Anggota yang mendaftar hari ini</div>
            <div
              class="
                card-footer
                d-flex
                align-items-center
                justify-content-between
              "
            >
              <h2>{{ anggota_mendaftar_hari_ini }}</h2>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-md-6">
          <div class="card bg-danger text-white mb-4">
            <div class="card-body">Anggota menunggu pembayaran</div>
            <div
              class="
                card-footer
                d-flex
                align-items-center
                justify-content-between
              "
            >
              <h2>{{ anggota_menunggu_pembayaran }}</h2>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-xl-8">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Frekuensi Pendaftar</h5>
              <h6 class="card-subtitle mb-2 text-muted">
                Frekuensi pendaftar per bulan dalam 1 tahun
              </h6>

              <p class="card-text">
                <FrekuensiPendaftar
                  :data="frekuensi_pendaftar"
                  v-if="!isChartLoading"
                />
              </p>
            </div>
          </div>
        </div>
        <div class="col-xl-4">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Status KTA</h5>
              <h6 class="card-subtitle mb-2 text-muted">
                Status KTA pada setiap anggota
              </h6>
              <p class="card-text">
                <StatusKTA v-if="!isChartLoading" :data="total_status_kta" />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-xl-6">
          <top-location
            :data="top_ten_wilayah_anggota"
            title="Top 10 Lokasi Anggota Terbanyak"
          />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import StatusKTA from "./components/StatusKTA.vue";
import FrekuensiPendaftar from "./components/FrekuensiPendaftar.vue";
import TopLocation from "./components/TopLocation.vue";
import {
  authenticationService,
  dashboardService,
  provinsiService,
  kotaService,
  kecamatanService,
  kelurahanService,
} from "@/services";

export default {
  components: {
    StatusKTA,
    FrekuensiPendaftar,
    TopLocation,
  },

  data() {
    return {
      total_anggota: 0,
      anggota_mendaftar_hari_ini: 0,
      anggota_menunggu_pembayaran: 0,
      top_ten_wilayah_anggota: [],
      total_status_kta: "",
      frekuensi_pendaftar: [],
      isChartLoading: true,
      frekuensiWilayah: "dalam wilayah ",

      propinsi_id: "",
      provinsi: [{ nama: "Semua Provinsi", id: "" }],
      kota_id: "",
      kota: [{ nama: "Semua Kota", id: "" }],
      kecamatan_id: "",
      kecamatan: [{ nama: "Semua Kecamatan", id: "" }],
      currentUser: authenticationService.currentUserValue,
    };
  },

  methods: {
    async loadTotalAnggota(propinsi_id, kota_id, kecamatan_id) {
      try {
        const total_anggota = await dashboardService.getTotalAnggota(
          propinsi_id,
          kota_id,
          kecamatan_id
        );
        this.total_anggota = total_anggota.data;
      } catch (error) {
        console.error(error);
      }
    },

    async loadTotalPendaftarToday(propinsi_id, kota_id, kecamatan_id) {
      try {
        const anggota_mendaftar_hari_ini =
          await dashboardService.getPendaftarHariIni(
            propinsi_id,
            kota_id,
            kecamatan_id
          );
        this.anggota_mendaftar_hari_ini = anggota_mendaftar_hari_ini.data;
      } catch (error) {
        console.error(error);
      }
    },

    async loadFrekuensiPendaftar(propinsi_id, kota_id, kecamatan_id) {
      try {
        const frekeunsi_pendaftar =
          await dashboardService.getFrekuensiPendaftar(
            propinsi_id,
            kota_id,
            kecamatan_id
          );
        this.frekuensi_pendaftar = frekeunsi_pendaftar.data.map(
          (data) => data.total_anggota
        );
      } catch (error) {
        console.error(error);
      }
    },

    async loadWilayahAnggota(propinsi_id, kota_id, kecamatan_id) {
      try {
        const wilayah_anggota_paling_banyak =
          await dashboardService.getWilayahAnggotaPalingBanyak(
            propinsi_id,
            kota_id,
            kecamatan_id
          );
        this.top_ten_wilayah_anggota = wilayah_anggota_paling_banyak.data;
      } catch (error) {
        console.error(error);
      }
    },

    async loadStatusKTA(propinsi_id, kota_id, kecamatan_id) {
      try {
        const total_status_kta = await dashboardService.getTotalStatusKTA(
          propinsi_id,
          kota_id,
          kecamatan_id
        );
        this.total_status_kta = total_status_kta.data.map(
          (data) => data.total_anggota
        );
      } catch (error) {
        console.error(error);
      }
    },

    async initialize() {
      this.isChartLoading = true;

      // if (this.currentUser.role === "PAC") {
      //   this.propinsi_id = this.currentUser.propinsi_id;
      //   this.kota_id = this.currentUser.kota_id;
      //   this.kecamatan_id = this.currentUser.kecamatan_id;
      // }

      await this.loadTotalAnggota(
        this.propinsi_id,
        this.kota_id,
        this.kecamatan_id
      );

      await this.loadTotalPendaftarToday(
        this.propinsi_id,
        this.kota_id,
        this.kecamatan_id
      );

      await this.loadFrekuensiPendaftar(
        this.propinsi_id,
        this.kota_id,
        this.kecamatan_id
      );

      await this.loadStatusKTA(
        this.propinsi_id,
        this.kota_id,
        this.kecamatan_id
      );

      await this.loadWilayahAnggota(
        this.propinsi_id,
        this.kota_id,
        this.kecamatan_id
      );

      this.isChartLoading = false;

      if (this.currentUser.role === "Super User") {
        // Get propinsi
        provinsiService.getAll(1000, "", 1).then((resp) => {
          this.provinsi = resp.rows;
        });
      } else {
        // Get kota
        kotaService
          .getAll("", "", "", "", "", this.currentUser.propinsi_id)
          .then((resp) => {
            this.kota = resp.rows;
          });
      }
    },

    onProvinceChange(propinsi_id) {
      kotaService.getAll("", "", "", "", "", propinsi_id).then((resp) => {
        this.kota = resp.rows;
      });
    },

    onKotaChange(kota_id) {
      kecamatanService.getAll("", "", "", "", "", kota_id).then((resp) => {
        this.kecamatan = resp.rows;
      });
    },

    onKecamatanChange(kecamatan_id) {
      kelurahanService.getAll("", "", "", "", "", kecamatan_id).then((resp) => {
        this.kelurahan = resp.rows;
      });
    },

    async resetFilter() {
      this.propinsi_id = "";
      this.kota_id = "";
      this.kecamatan_id = "";
      await this.initialize();
    },
  },

  async mounted() {
    await this.initialize();
  },
};
</script>

<style scoped>
.dashboard {
  padding: 3rem 1.5rem;
  text-align: center;
}
</style>
